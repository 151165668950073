import axios from 'axios'
import appConfig from '@/config'

const aiServiceApi = () => axios.create({
  baseURL: appConfig.aiService.apiUrl(),
  timeout: appConfig.aiService.apiTimeout * 1000,
  headers: {
    'Content-Type': 'application/json'
  }
})

export default aiServiceApi
